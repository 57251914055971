import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navbar-mix-list',
  templateUrl: './navbar-mix-list.component.html',
  styleUrls: ['./navbar-mix-list.component.css']
})
export class NavbarMixListComponent implements OnInit {

  @Output() sidenavClose = new EventEmitter();
  //public links = [{link: 'Inicio', fragment: 'inicio'}, {link: 'El Proceso', fragment: 'proceso'}, {link: 'Historia', fragment: 'historia'}, {link: 'Comprar', fragment: 'comprar'}, {link: 'Seamos Amigos', fragment: 'amigos'}, {link: 'Paquetes', fragment: 'paquetes'}, {link: 'Contacto', fragment: 'contacto'}]
  public links = [{link: 'Inicio', fragment: 'inicio'}, {link: 'El Proceso', fragment: 'proceso'}, {link: 'Historia', fragment: 'historia'}, {link: 'Comprar', fragment: 'comprar'}, {link: 'Seamos Amigos', fragment: 'amigos'}]
  public active: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

}
