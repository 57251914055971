import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

/**Video */
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";

/**Flex */
import { FlexLayoutModule } from "@angular/flex-layout";

/**Angular Material */
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

//Pagina Principal
import { MainComponent } from "./components/theme/main/main.component";
import { NavbarComponent } from "./components/theme/main/navbar/navbar.component";
import { FooterComponent } from "./components/theme/main/footer/footer.component";
import { ListMenuComponent } from "./components/theme/main/list-menu/list-menu.component";
import { MayoriaEdadComponent } from "./components/mayoria-edad/mayoria-edad.component";
import { FooterContactoComponent } from "./components/theme/main/footer/footer-contacto/footer-contacto.component";
import { MixologiaComponent } from "./components/theme/mixologia/mixologia.component";
import { NavbarMixComponent } from './components/theme/mixologia/navbar-mix/navbar-mix.component';
import { MessageComponent } from "./components/theme/main/footer/footer-contacto/message/message.component";
import { MessageerrorComponent } from "./components/theme/main/footer/footer-contacto/messageerror/messageerror.component";
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingInterceptor } from "./services/loading-interceptor";
import { FooterPrincipalComponent } from "./components/main/footer-principal/footer-principal.component";
import { NavbarMixListComponent } from './components/theme/mixologia/navbar-mix/navbar-mix-list/navbar-mix-list.component';
import { AvisoComponent } from './components/aviso/aviso.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavbarComponent,
    FooterComponent,
    ListMenuComponent,
    MayoriaEdadComponent,
    FooterContactoComponent,
    MixologiaComponent,
    NavbarMixComponent,
    MessageComponent,
    MessageerrorComponent,
    LoadingComponent,
    FooterPrincipalComponent,
    NavbarMixListComponent,
    AvisoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass:LoadingInterceptor,
      multi:true
    },
    { provide: MAT_DATE_LOCALE, useValue: "es-MX" }
  ],
  bootstrap: [AppComponent],
  exports: [CommonModule, TranslateModule],
  entryComponents:[MessageComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
