import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { url } from '../models/url.model';
import { urlLanding } from '../models/url.model';
@Injectable({
  providedIn: 'root'
})
export class GeneralService implements CanActivate {

  private url=url;
  private urlLanding=urlLanding;
  private urlI='https://graph.instagram.com/'
  private accesstoken:string="IGQVJVSUxUZA0FNZA3R3VEI2cXBPMmczbC1tM1o0Tm1CMWlnTmVwZAmZACaVFITHRDcnFtLTJNenh1dHVJVmFMLUdjeGFIYm1ISF9oamNRMnZAfTXNBTmdOdTRZAN1lBVWhkRVBLVnBIVFlKZA3NTVDVtZAGZApegZDZD";
  constructor(private router: Router,private http: HttpClient) {

  }

  redirect(opcion: boolean): any {
    if (opcion === false) {
      this.router.navigate(['/home']);
    }else{
      this.router.navigate(['/mayor'])
    }
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  canActivate() {
    const mayor = JSON.parse(sessionStorage.getItem('Mayor'));
    if (mayor == true) {
      this.redirect(false);
      return false;
    }else{
      this.redirect(true);
      return false;
    }
  }
  // public enviarContacto(data: any): Observable<any> {
  //   console.log("Info enviada",data)
  //   return this.http.post(this.url + 'users/v2/enviar/mensaje/', data);
  // }
  public enviarContacto(data: any): Observable<any> {
    console.log("Info enviada",data)
    return this.http.post(this.url + 'landing/makecontact/', data);
  }
  // public obtenerToken():Observable<any> {
  //   return this.http.get(this.urlLanding + 'tokens/v1/Tok/cru/?id=2');
  // }
  public obtenerToken():Observable<any> {
    return this.http.get(this.url + 'landing/tokens/v1/Tok/cru/?id=1');
  }
  public obtenerURL(accessToken):Observable<any>{  
  return this.http.get(this.urlI+'me/media?fields=permalink&access_token=' + accessToken);
  }
  public obtenerhashtag():Observable<any> {
    return this.http.get("https://graph.facebook.com/v11.0/ig_hashtag_search?user_id=17841405309211844&q=bluebottle&access_token="+this.accesstoken);
  }
  public obtenerURLMedia(id,accessToken):Observable<any>{  
    return this.http.get(this.urlI + id +'?fields=id,media_type,media_url,username,timestamp&access_token=' + accessToken);
  }
  //https://graph.instagram.com/me?fields=id,username&access_token=IGQVJYenZATeEFnNmxJS3JyRVFQc2Vlb0pzSklqUGNUSnNSM3dMbm94cVVhWi1rUXc1ZAmdfWmtjYkFybTB3LXhlVmJ4VW1uTDhwU1JNRmExcm42RFFta2M5VURBU2pjTnY4c3dLRXpiSGRuVGhvN3pNUAZDZD
  // Polimentes_ID:33467148303
}