import { Component, OnInit } from '@angular/core';
import { vers } from 'src/app/models/url.model';

@Component({
  selector: 'app-footer-principal',
  templateUrl: './footer-principal.component.html',
  styleUrls: ['./footer-principal.component.css']
})
export class FooterPrincipalComponent implements OnInit {

  public year = new Date().getFullYear();
  public links = [{link: 'El Proceso', fragment: 'proceso'}, {link: 'Historia', fragment: 'historia'}, {link: 'Comprar', fragment: 'comprar'}, {link: 'Seamos Amigos', fragment: 'amigos'}]
  public vers: any = vers;
  constructor() { }

  ngOnInit(): void {
  }

}
