import { Component, OnInit } from '@angular/core';
import { vers } from 'src/app/models/url.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public year = new Date().getFullYear();
  public links = [{link: 'El Proceso', fragment: 'proceso'}, {link: 'Historia', fragment: 'historia'}, {link: 'Comprar', fragment: 'comprar'}, {link: 'Seamos Amigos', fragment: 'amigos'}]
  public vers: any = vers;
  constructor() { }

  ngOnInit(): void {
  }

}
