import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navbar-mix',
  templateUrl: './navbar-mix.component.html',
  styleUrls: ['./navbar-mix.component.css']
})
export class NavbarMixComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();
  //public links = [{link: 'Inicio', fragment: 'inicio'}, {link: 'El Proceso', fragment: 'proceso'}, {link: 'Historia', fragment: 'historia'}, {link: 'Comprar', fragment: 'comprar'}, {link: 'Seamos Amigos', fragment: 'amigos'}, {link: 'Paquetes', fragment: 'paquetes'}, {link: 'Contacto', fragment: 'contacto'}]
  public links = [{link: 'Inicio', fragment: 'inicio'}, {link: 'El Proceso', fragment: 'proceso'}, {link: 'Historia', fragment: 'historia'}, {link: 'Comprar', fragment: 'comprar'}, {link: 'Seamos Amigos', fragment: 'amigos'}]
  public active: number = 0;
  public navbarfixed: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  @HostListener('window:scroll', ['$event']) onscroll() {
    if (window.scrollY > 5) {
      this.navbarfixed = true;
    } else {
      this.navbarfixed = false;
    }
  }
}
