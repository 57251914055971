import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-mixologia',
  templateUrl: './mixologia.component.html',
  styleUrls: ['./mixologia.component.css']
})

export class MixologiaComponent implements OnInit {
  public bebidas:Array<any>;
  constructor() {
    this.bebidas=[
      {
        imagenF:"CafeFront.png",
        imagenB:"CafeBack.png"
      },
      {
        imagenF:"RocasFront.png",
        imagenB:"RocasBack.png"
      },
      {
        imagenF:"LecheFront.png",
        imagenB:"LecheBack.png"
      },
      {
        imagenF:"MatchaFront.png",
        imagenB:"MatchaBack.png"
      },
      {
        imagenF:"SunshineFront.png",
        imagenB:"SunshineBack.png"
      },
      {
        imagenF:"LucaFront.png",
        imagenB:"LucaBack.png"
      },
      {
        imagenF:"BallaratFront.png",
        imagenB:"BallaratBack.png"
      },
      {
        imagenF:"BusseltonFront.png",
        imagenB:"BusseltonBack.png"
      },
      {
        imagenF:"BunburyFront.png",
        imagenB:"BunburyBack.png"
      },
      {
        imagenF:"LismoreFront.png",
        imagenB:"LismoreBack.png"
      },
      {
        imagenF:"BurnieFront.png",
        imagenB:"BurnieBack.png"
      }
    ]
   }

  ngOnInit(): void {
  }

}
