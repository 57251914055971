import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';
import { MessageComponent } from './message/message.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageerrorComponent } from './messageerror/messageerror.component';

@Component({
  selector: 'app-footer-contacto',
  templateUrl: './footer-contacto.component.html',
  styleUrls: ['./footer-contacto.component.css']
})
export class FooterContactoComponent implements OnInit {
  public form: FormGroup;
  public showLoad:boolean;

  constructor(private servicio:GeneralService,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.doForm();
  }

  doForm(): void {
    this.form = new FormGroup({
      full_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      matter:new FormControl('Nuevo Contacto'),
      company: new FormControl('',),
      phone: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });
  }
  submit(): void{
    if(this.form.invalid){
      this.form.markAllAsTouched();
    } else {
      this.showLoad = true;
      const data = {
        full_name: this.form.value.full_name,
        email: this.form.value.email,
        matter:'Nuevo Contacto',
        company: this.form.value.company,
        phone: this.form.value.phone,
        message: this.form.value.message,
      }
      this.servicio.enviarContacto(data).subscribe((res)=>{
        this.dialog.open(MessageComponent, {
          width: '650px',
        });
        this.form.reset();
      },
      (error)=>{
        this.dialog.open(MessageerrorComponent, {
          width: '650px',
        });
      });
    this.showLoad = false;
    }
  }
}
