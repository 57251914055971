import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from './services/general.service';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { MatDialog } from '@angular/material/dialog';
import { MayoriaEdadComponent } from './components/mayoria-edad/mayoria-edad.component';
import { AvisoComponent } from './components/aviso/aviso.component';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGATnaktZtvDUqn3kjdxSbOljNOznDOAM",
  authDomain: "macajillo-7c423.firebaseapp.com",
  projectId: "macajillo-7c423",
  storageBucket: "macajillo-7c423.appspot.com",
  messagingSenderId: "876298362216",
  appId: "1:876298362216:web:e700311c580e8ebcaa8add",
  measurementId: "G-1XPFYTYG9B"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title = 'Macajillo';
  public contador= {
    minutos: 0,
    decimas: 0,
    segundos: 5,
    intervalo: null,
  };
  public date=new Date();
  public endDate=new Date('2023/01/01');
  constructor(private translate: TranslateService, private servicio:GeneralService,public dialog: MatDialog) {
    document.body.classList.add("cdk-global-scrollblock");
    translate.setDefaultLang('es');
    translate.use('es');

    console.log(this.date,"Es mayor que",this.endDate);
    let respuesta=this.dialog.open(MayoriaEdadComponent, {
      width: '456px',
      height: '528px',
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });
    respuesta.afterClosed().subscribe((data: any) => {
      if (data === true) {
        document.body.classList.remove("cdk-global-scrollblock");
        if(this.date<=this.endDate){
          this.conteo();
         }
      }
    });
  }
  conteo() {
    this.contador.intervalo = setInterval(() => {
      if (this.contador.segundos < 1) {
        this.contador.segundos = 10;
        this.contador.decimas--;
      }
      if (this.contador.decimas < 0) {
        this.contador.decimas = 5;
        this.contador.minutos--;
      }
      this.contador.segundos--;
      if (this.contador.minutos === 0 && this.contador.decimas === 0 && this.contador.segundos === 0) {
        clearInterval(this.contador.intervalo);
        let respuesta=this.dialog.open(AvisoComponent, {
          width: '842px',
          panelClass: 'custom-dialog-container',
        });
      }
    }, 1000);
  }
}