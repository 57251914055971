import { LoadingService } from './loading.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptor implements HttpInterceptor {

    activeRequest = 0;

  constructor(private loadingServ: LoadingService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequest === 0) {
      this.loadingServ.startLoading();
    }

    this.activeRequest++;
    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequest--;
        if (this.activeRequest === 0) {
          this.loadingServ.hidenLoading();
        }
      })
    );
  }

}
