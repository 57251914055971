import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-mayoria-edad',
  templateUrl: './mayoria-edad.component.html',
  styleUrls: ['./mayoria-edad.component.css']
})
export class MayoriaEdadComponent implements OnInit {
  mayorEdad:boolean;
  constructor(private _location: Location, private servicio:GeneralService) { }

  ngOnInit(): void {
  }
  mayor(){
    this.mayorEdad=true;
    sessionStorage.setItem('Mayor', JSON.stringify(this.mayorEdad));
    this.servicio.canActivate();
  }
  back(){
    this._location.back();
  }

}
