import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  loading: false;
  loadingSus: Subscription;

  constructor(private loadingSer: LoadingService) {
  }

  ngOnDestroy() {
    this.loadingSus.unsubscribe();
  }

  ngOnInit() {
    this.loadingSus = this.loadingSer.loadingStatus.subscribe((value: any) => {
      this.loading = value;
    });
  }
}
