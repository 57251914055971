import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { MayoriaEdadComponent } from "./components/mayoria-edad/mayoria-edad.component";
import { MainComponent } from "./components/theme/main/main.component";
import { MixologiaComponent } from "./components/theme/mixologia/mixologia.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 64],
  onSameUrlNavigation: "reload",
};

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./components/main/main.module").then((m) => m.MainModule),
      },
    ],
  },
  { path: "mayor", component: MayoriaEdadComponent },
  { path: "mixologia", component: MixologiaComponent},
  { path: '**', pathMatch: 'full', redirectTo: 'home/404' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
